import { isNullOrEmpty, isString } from './Is'
import { logParseJson } from './Log'

export const getObj = (stringJson: any, df = {}, { cbError }: any = {}) => {
  if (isString(stringJson)) {
    try {
      return JSON.parse(stringJson)
    } catch (error) {
      if (cbError) {
        cbError()
      }
      logParseJson(stringJson, error)
    }
  }
  return df
}

export const getString = (obj: object, df = '', { cbError }: any = {}) => {
  if (!isNullOrEmpty(obj)) {
    try {
      return JSON.stringify(obj)
    } catch (error) {
      if (cbError) {
        cbError()
      }
      logParseJson('[getString]', obj, error)
    }
  }
  return df
}

export const json2Obj = getObj
export const obj2Json = getString
