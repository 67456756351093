import LodashGet from 'lodash/get'
import LodashSet from 'lodash/set'
export const get = LodashGet
export const set = LodashSet
export const CF = {}

export const setCF = (key: string | any, value: any) => {
  if (key) {
    CF[key] = value
  }
}

export const setCFMultiObj = (obj: any) => {
  if (obj) {
    for (let k of Object.keys(obj)) {
      CF[k] = obj[k]
    }
  }
}

export const getCF = (key: string | any) => {
  if (key) {
    return CF[key]
  }
  return null
}

export const setCFMultiLevel = (key: string | any, value: any) => {
  if (key) {
    set(CF, key, value)
  }
}

export const getCFMultiLevel = (key: string | any) => {
  if (key) {
    return get(CF, key)
  }
  return null
}
