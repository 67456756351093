export const RGSquareBracket = /\[([^\]]+)\]/g
export const RGSquareBracketReplace = /\[(.[^\]]*)\]/gm
export const RGHexColor = /#([a-f0-9]{6}|[a-f0-9]{3})/g
export const RGEmail =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
// export const RGEmail =
//   /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
//bo sung phone check
export const RGPhone = /^(\+\d{1,3}[- ]?)?\d{8,12}$/

export const getOneGroupOfMatchRegex = (
  patern: string = '',
  text: string = ''
) => {
  if (patern && text) {
    let _rg = new RegExp(patern)
    let _match = _rg.exec(text)
    if (_match && _match[1]) {
      return _match[1]
    }
  }
  return null
}
